import React from "react";
import '../utils/css/otherProduct.css';

export default function OtherProduct({ img, title, text, category, setCategory }) {
    return (
        <div className="otherProduct_global-container">
            <img src={img} className="otherProduct-img" alt={title} />
            <div className="otherProduct_text_btn-container">
                <div className="otherProduct_title-container">
                    <h3 className="otherProduct-title">{title}</h3>
                    <p className="otherProduct-subtitle">{text}</p>
                </div>
                <button
                    onClick={() => setCategory(category)}
                    className="otherProduct-btn"
                >
                    Discover
                </button>
            </div>
        </div>
    );
}
