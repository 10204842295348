import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "./DropDown";
import "../utils/css/header.css";
import phone from "../images/header/phone.png";
import logo from "../images/AKORE-img/logo/Logo AKORE noir.svg";
import logoOcre from "../images/AKORE-img/logo/Logo AKORE ocre.svg";
import dropDownIcon from "../images/general/arrow_drop_down.png";

export default function Header() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const location = useLocation();
    const navigate = useNavigate();

    const handleDropdownClick = (event) => {
        const rect = event.target.getBoundingClientRect();
        setDropdownPosition({
            top: rect.bottom + window.scrollY + 15, // Position sous l'élément cliqué
            left: rect.left + window.scrollX, // Aligner avec la gauche de l'élément
        });
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavigate = (path, state = {}) => {
        navigate(path, { state });
        setIsDropdownOpen(false); // Fermer le menu après navigation
    };

    const isHomeB2B = location.pathname === "/homeb2b";

    // Définition des options du menu déroulant AVANT de les parcourir
    const dropdownOptions = isHomeB2B
        ? [
            { label: "Serums", path: "/homeb2b", state: { category: "Serums" } },
            { label: "Mature Skin Routine", path: "/homeb2b", state: { category: "Mature Skin Routine" } },
            { label: "Acne-Prone Skin Routine", path: "/homeb2b", state: { category: "Acne-Prone Skin Routine" } },
        ]
        : [
            { label: "Mature Skin Routine", path: "/homeb2b", state: { category: "Mature Skin Routine" } },
            { label: "Acne-Prone Skin Routine", path: "/homeb2b", state: { category: "Acne-Prone Skin Routine" }}
        ];

    return (
        <div className="header-div-container">
            <div className="header-div-menu">
                <Link to="/">
                    <img className="header-img-menu" alt="logo" src={logo} />
                </Link>

                {/* Raw Materials / Serums */}
                <p
                    className="header-item"
                    onClick={() => handleNavigate(isHomeB2B ? "/homeb2b" : "/serums", { category: "Raw Materials" })}
                    style={{ cursor: "pointer" }}
                >
                    {isHomeB2B ? "Raw Materials" : "Serums"}
                </p>


                {/* Élément déclencheur du menu déroulant */}
                <div className="header_dropdown-container" onClick={handleDropdownClick} style={{ cursor: "pointer" }}>
                    <p className="header-item">{isHomeB2B ? "Finished Product" : "Skincare Routines"}</p>
                    <img src={dropDownIcon} alt="dropdown icon" />
                </div>

                {/* Contenu du menu déroulant */}
                <Dropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} position={dropdownPosition}>
                    <div className="dropdown-content">
                        {dropdownOptions.map((option, index) => (
                            <div
                                key={index}
                                className="dropdown-item"
                                onClick={() => handleNavigate(option.path, option.state)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                </Dropdown>


                <button className="header_business-btn" onClick={() => handleNavigate(isHomeB2B ? "/" : "/homeb2b")}>
                    {isHomeB2B ? "Individual customer (b2c)" : "Business customers (b2b)"}
                </button>
            </div>
        </div>
    );
}
