import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import du hook pour la navigation
import '../utils/css/produits.css';
import ProduitCard from "../composants/produits/ProduitCard";
import { getFinishedProducts } from "../utils/produits/produitsInfos";

import fondMp from '../images/AKORE-img/Home/fond-MP.png';
import fondMpPhone from '../images/AKORE-img/Home/fond-MP-phone.png';

export default function Serum() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate(); // Initialisation de useNavigate

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className='container-all'>
            <div className='container-product-all'>
                <div className='container-product-category' id='finishedProducts'>
                    <div className='item_in_product-category text_in_product_category'>
                        <h2 className='text'>SERUMS</h2>
                        <p className='small-text text'>A selection of finished</p>
                        <p className='small-text text'>liposomal products</p>
                    </div>
                    {getFinishedProducts().map((product, index) => (
                        <div key={index} className='item_in_product-category'>
                            <ProduitCard
                                name={product.name}
                                shortDesc={product.shortDesc}
                                imgName={product.imgName}
                                productKey={product.key}
                                isRowProduct={false}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className='akore-home-container-4'>
                <div className='container-4-image-text'>
                    {windowWidth > 600 ? (
                        <img alt='matière première' src={fondMp} />
                    ) : (
                        <img alt='matière première' src={fondMpPhone} />
                    )}
                    <div className='home-c-c4'>
                        <h2 className='home-c4-h2'>SKINCARE ROUTINES</h2>
                        <h3 className='home-c4-h3'>A selection of raw materials</h3>
                        <button 
                            className='home_serums-btn w280' 
                            onClick={() => navigate('/routines')}
                        >
                            Discover our skincare routines
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
