import React, { useEffect, useRef } from "react";

export default function Dropdown({ isOpen, setIsOpen, children, position }) {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, setIsOpen]);

    if (!isOpen) return null;

    return (
        <div
            ref={dropdownRef}
            className="dropdown-menu"
            style={{
                position: "absolute",
                top: position.top,
                left: position.left,
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "10px",
                zIndex: 1000,
            }}
        >
            {children}
        </div>
    );
}
