import React from "react";

import routineA_1 from "../images/AKORE WEBSITE/PRODUITS/routineA_1.png";
import routineA_2 from "../images/AKORE WEBSITE/PRODUITS/routineA_2.png";
import routineA_3 from "../images/AKORE WEBSITE/PRODUITS/routineA_3.png";
import routineA_4 from "../images/AKORE WEBSITE/PRODUITS/routineA_4.png";
import routineA_5 from "../images/AKORE WEBSITE/PRODUITS/routineA_5.png";
import routineA_6 from "../images/AKORE WEBSITE/PRODUITS/routineA_6.png";
import routineB_1 from "../images/AKORE WEBSITE/PRODUITS/routineB_1.png";
import routineB_2 from "../images/AKORE WEBSITE/PRODUITS/routineB_2.png";
import routineB_3 from "../images/AKORE WEBSITE/PRODUITS/routineB_3.png";
import routineB_4 from "../images/AKORE WEBSITE/PRODUITS/routineB_4.png";
import routineB_5 from "../images/AKORE WEBSITE/PRODUITS/routineB_5.png";

const RoutineProduct = ({ type }) => {
    const tabType1 = {
        "Clean & Protect": [
            {
                image: routineA_1,
                name: "Anti-aging cleansing gel",
                ingredients: ["Squalene 5%", "Coenzyme Q10 1%"],
            },
            {
                image: routineA_2,
                name: "Anti-aging toner",
                ingredients: ["Collagen 3%", "Ceramides 2%"],
            },
        ],
        "Hydrate & Revitalize": [
            {
                image: routineA_3,
                name: "Hyaluronic acid serum",
                ingredients: ["Hyaluronic acid 3%", "Vitamin B5 2%"]
            },
            {
                image: routineA_4,
                name: "Anti-aging eye contour",
                ingredients: ["Collagen 3%", "Caffeine 3%", "Vitamin C 3%", "Hyaluronic acid 2%", "Ceramides 1%"]
            }
        ],
        "Firm Up & Regenerate": [
            {
                image: routineA_5,
                name: "Anti-aging day cream",
                ingredients: ["Squalene 5%", "Vitamin C 5%", "Tranexamic acid 2%", "Ferulic acid 1%", "Resveratrol 0.5%"]
            },
            {
                image: routineA_6,
                name: "Anti-aging night cream",
                ingredients: ["Collagen 3%", "Coenzyme Q10 1%", "Melatonin 0.5%", "Glutathione 0.5%"]
            }
        ]
    };

    const tabType2 = {
        "Clean & Balance": [
            {
                image: routineB_1,
                name: "Anti-Blemish Cleansing Gel",
                ingredients: ["Zinc PCA 3%", "Azelaic acid 1%"],
            },
            {
                image: routineB_2,
                name: "Anti-Blemish Toner",
                ingredients: ["Niacinamide 2%", "Ceramides 1%"],
            },
        ],
        "Sublimate": [
            {
                image: routineB_3,
                name: "Niacinamide Serum",
                ingredients: ["Niacinamide 10%", "Zinc PCA 1%"],
            },
        ],
        "Repair & Regenerate": [
            {
                image: routineB_4,
                name: "Anti-Blemish Day Cream",
                ingredients: ["Azelaic acid 2%", "Biotin 1%", "Bakuchiol 1%", "Ferulic acid 1%"],
            },
            {
                image: routineB_5,
                name: "Anti-Blemish Night Cream",
                ingredients: ["Azelaic acid 5%", "Niacinamide 4%", "Squalene 3%", "Vitamin B5 2%", "Allantoin 1%"],
            },
        ],
    };

    // Sélectionner le bon tableau en fonction du `type`
    const selectedTab = type === 1 ? tabType1 : tabType2;

    return (
        <div className="routineProduct_global-container">
            {Object.entries(selectedTab).map(([category, products], index) => (
                <div key={category} className="routineProduct_category">
                    <div className="routineProduct_title_product-container">
                        <p className="routineProduct_product-index">{`0${index + 1}.`}</p>
                        <h2 className="routineProduct_product-title">{category}</h2>
                        <div className="routineProduct_horizontal-bar"></div>
                    </div>
                    <div className="routineProduct_items">
                        {products.map((product, productIndex) => (
                            <div key={productIndex} className="routineProduct_item">
                                <img src={product.image} alt={product.name} className="routineProduct-img" />
                                <h3 className="routineProduct_product_name">{product.name}</h3>
                                <ul className="routineProduct-list">
                                    {product.ingredients.map((ingredient, idx) => (
                                        <li className="routinePorudct_product-ingredients" key={idx}>
                                            {idx === 0 ? ingredient : `+ ${ingredient}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RoutineProduct;
