import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import "../utils/css/routine.css"

import fondProduits from '../images/AKORE-img/Home/fond-produits.jpg'
import fondProduitsPhone from '../images/AKORE-img/Home/fond-produits-phone.png'

import RoutineProduct from '../composants/RoutineProduct';

export default function Routines() {
    const location = useLocation();
    const initialType = location.state?.type || 1; // Défaut à 1 si non défini

    const [typeState, setTypeState] = useState(initialType);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='routine_global-container'>
            <div className='routine_intro-container'>
                <h1 className='routine_intro-title'>Skincare routines</h1>
                <div className='horizontal-bar'></div>
                <p className='routine_intro-subtitle'>
                    {typeState === 2
                        ? "A made-to-measure routine for radiant skin, day after day."
                        : "A tailor-made routine for radiant skin, day after day."
                    }
                </p>
                <div className='routine_intro_btn-container'>
                    <button
                        className={`routiner_intro-btn ${typeState === 1 ? 'active' : ''}`}
                        onClick={() => setTypeState(1)}
                    >
                        Mature skin
                    </button>
                    <button
                        className={`routiner_intro-btn pink ${typeState === 2 ? 'active' : ''}`}
                        onClick={() => setTypeState(2)}
                    >
                        Acne-prone skin
                    </button>
                </div>
                <p className='routine_intro-text'>
                    {typeState === 2
                        ? (
                            <>
                                Bring your blemish-prone skin back into balance with a targeted routine.
                                <br /><br />
                                Designed to purify, soothe and regenerate acne-prone skin, our selection combines niacinamide, azelaic acid and zinc for maximum effectiveness.
                                This routine helps to reduce shine, refine skin texture and prevent the appearance of imperfections, while maintaining optimal hydration.
                            </>
                        ) : (
                            <>
                                Take care of your mature skin with an expert routine, enriched with powerful anti-ageing active ingredients.
                                <br /><br />
                                Our selection of skincare products work in depth to cleanse, intensely moisturise and stimulate cell regeneration.
                                Thanks to ingredients such as collagen, vitamin C and squalane, your skin regains suppleness, firmness and radiance.
                                Apply these products morning and night for visible, long-lasting results.
                            </>
                        )
                    }
                </p>
            </div>

            {/* RoutineProduct avec type dynamique */}
            <RoutineProduct type={typeState} />

            <div className='akore-home-container-4'>
                <div className='container-4-image-text'>
                    {windowWidth > 600 ? (
                        <img alt='matière première' src={fondProduits} />
                    ) : (
                        <img alt='matière première' src={fondProduitsPhone} />
                    )}
                    <div className='home-c-c4'>
                        <h2 className='home-c4-h2'>SERUMS</h2>
                        <h3 className='home-c4-h3'>A selection of finished niosomal products</h3>
                        <button className='home_serums-btn'>Discover our serums</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
