import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import du hook pour la navigation

import '../utils/css/akore.css';
import imgIntro from '../images/AKORE-img/Home/femme-desert.jpg';
import imgIntroPhone from '../images/AKORE-img/Home/femme-desert-phone.png';

import drop from '../images/AKORE-img/Home/drop-flacon.png';
import liposomeSchema from '../images/AKORE-img/Home/liposome-schema.png';

import icon1 from '../images/AKORE-img/Home/icon1.svg';
import icon2 from '../images/AKORE-img/Home/icon2.svg';
import icon3 from '../images/AKORE-img/Home/icon3.svg';
import icon4 from '../images/AKORE-img/Home/icon4.svg';

import fondMp from '../images/AKORE-img/Home/fond-MP.png';
import fondMpPhone from '../images/AKORE-img/Home/fond-MP-phone.png';
import fondProduits from '../images/AKORE-img/Home/fond-produits.jpg';
import fondProduitsPhone from '../images/AKORE-img/Home/fond-produits-phone.png';

export default function Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate(); // Initialisation de useNavigate

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='container-home'>
            <div className='container-intro-home'>
                {windowWidth > 600 ? (
                    <img src={imgIntro} alt='femme' className='home-imgIntro' />
                ) : (
                    <img src={imgIntroPhone} alt='femme' className='home-imgIntro' />
                )}
                
                <div className='home-div-onImage'>
                    <h1 className='home-title-bold'>NIOSOMAL</h1>
                    <h1 className='home-title'>TECHNOLOGY</h1>
                    <div className='line-bold'></div>
                    <p className='home-p-bold'>The revolution in cosmetics.</p>
                    <p className='home-p'>Your best choice for maximum assimilation of active ingredients.</p>
                </div>
            </div>

            <div className='container-text-intro-background'>
                <p className='text-intro-background'>Offering innovative products with bioavailability.</p>
                <p className='text-intro-background'>Maximized for efficiency focused and deep assured.</p>
            </div>

            <div className='container-content-global-home'>
                <div className='container-expert-content'>
                    <img className='img-drop' alt='expert' src={drop} />
                    <div className='container-text-expert-content'>
                        <h2 className='title-expert-content'>
                            Innovation and efficacy at the heart of cosmetics 
                        </h2>
                        <p className='text-expert-content'>
                            At Akore Formulations, we’re revolutionizing the world of cosmetics through innovation and scientific expertise.
                            We use niosome technology to push back the boundaries of cosmetic efficacy.
                        </p>
                        <p className='text-expert-content'>
                            Compared to traditional cosmetic formulations, niosome-based cosmetics offer numerous advantages,
                            delivering active ingredients into the skin’s deepest layers for rapid, long-lasting action.
                        </p>
                        <button 
                            className='button-expert-content' 
                            onClick={() => navigate('/produits')}
                        >
                            Discover our products
                        </button>
                    </div>
                </div>

                <div className='akore-home-container-2'>
                    <div className='container-liposome-advantage'>
                        <div className='container-content-liposome-advantag'>
                            <h2 className='title-liosome'>What’s a niosome ?</h2>
                            <p className='home-p-desc align-justified'>
                                Niosomes are non-ionic encapsulation systems designed to enhance the delivery of active ingredients to the skin.
                                Thanks to their unique structure, they effectively encapsulate ingredients, enabling controlled release and improved skin penetration.
                                The result is a significant increase in the efficacy of cosmetic products, while ensuring optimum protection of sensitive active ingredients.
                            </p>
                        </div>
                        <img className='akore-container-2-img' alt='avantages' src={liposomeSchema} />
                    </div>

                    <div className='container-liposome-advantage2'>
                        <div className='container-content-liposome-advantag'>
                            <h2 className='title-liosome'>Our expertise</h2>
                            <p className='home-p-desc align-justified'>
                                Our laboratory is at the forefront of research and development in the field of niosomes.
                                We work closely with experts in dermatology and cosmetic formulation to create products that meet the specific needs of our customers.
                            </p>
                        </div>

                        <div className='container-content-liposome-advantag'>
                            <h2 className='title-liosome'>Our commitments</h2>
                            <p className='home-p-desc-2'><strong>Innovation :</strong> We are committed to developing cutting-edge solutions that set new standards in the cosmetics industry.</p>
                            <p className='home-p-desc-2'><strong>Quality :</strong> Each product is the result of in-depth research and rigorous quality control, ensuring safety and efficacy.</p>
                            <p className='home-p-desc-2'><strong>Customisation :</strong> We offer tailor-made services to meet the unique requirements of each brand, enabling it to stand out from the crowd, from raw material to finished products.</p>
                        </div>
                    </div>
                </div>

                <div className='akore-home-container-3'>
                    <div className='akore-home-c3-duo'>
                        <div className='akore-home-icone-text'>
                            <img className='icone-container-3' alt='icone goutte' src={icon1} />
                            <h3>Powerful,</h3>
                            <h3>profound</h3>
                            <h3>effectiveness</h3>
                        </div>
                        <div className='akore-home-icone-text'>
                            <img className='icone-container-3' alt='icon molécule graphe' src={icon2} />
                            <h3>Long-Lasting Action :</h3>
                            <h3>Controlled </h3>
                            <h3>& Prolonged Release</h3>
                        </div>
                    </div>
                </div>

                <div className='akore-home-container-4'>
                    <div className='container-4-image-text'>
                        <img alt='matière première' src={windowWidth > 600 ? fondProduits : fondProduitsPhone} />
                        <div className='home-c-c4'>
                            <h2 className='home-c4-h2'>SERUMS</h2>
                            <h3 className='home-c4-h3'>A selection of finished niosomal products</h3>
                            <button className='home_serums-btn' onClick={() => navigate('/serums')}>
                                Discover our serums
                            </button>
                        </div>
                    </div>

                    <div className='container-4-image-text'>
                        <img alt='matière première' src={windowWidth > 600 ? fondMp : fondMpPhone} />
                        <div className='home-c-c4'>
                            <h2 className='home-c4-h2'>SKINCARE ROUTINES</h2>
                            <h3 className='home-c4-h3'>A selection of raw materials</h3>
                            <button className='home_serums-btn w280' onClick={() => navigate('/routines')}>
                                Discover our skincare routines
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
