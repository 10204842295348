import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import '../utils/css/homeB2b.css'

import imgIntro from '../images/AKORE-img/Home/femme-desert.jpg';
import imgIntroPhone from '../images/AKORE-img/Home/femme-desert-phone.png';

import icon1 from '../images/AKORE-img/Home/icon1.svg';
import icon2 from '../images/AKORE-img/Home/icon2.svg';
import drop from '../images/AKORE-img/Home/drop-flacon.png';
import liposomeSchema from '../images/AKORE-img/Home/liposome-schema.png';
import fondMp from '../images/AKORE-img/Home/raw.png';
import fondMpPhone from '../images/AKORE-img/Home/fond-MP-phone.png';
import fondProduits from '../images/AKORE WEBSITE/IMAGES/homeB2b_finishedProduct.png';
import fondProduitsPhone from '../images/AKORE-img/Home/fond-produits-phone.png';

import icon_otherProduct1 from "../images/AKORE WEBSITE/PRODUITS/serum-MP--ALLANTOIN.png"
import icon_otherProduct2 from "../images/AKORE WEBSITE/PRODUITS/serum-HYALURONIC-ACID.png"
import icon_otherProduct3 from "../images/AKORE WEBSITE/PRODUITS/routineA_1.png"

import ProduitCard from "../composants/produits/ProduitCard";
import RoutineProduct from '../composants/RoutineProduct';
import OtherProduct from '../composants/OtherProduct.js'
import { getFinishedProducts, getRawProducts } from "../utils/produits/produitsInfos";

export default function HomeB2B() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const [category, setCategory] = useState(() => location.state?.category || "Default Category");
    const initialType = location.state?.type || 1;

    const [typeState, setTypeState] = useState(initialType);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location.state?.category) {
            setCategory(location.state.category);
        }
    }, [location.state]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [category]);    

    return (
        <div className="HomeB2b_global-container">
            {/* homepage pro */}
            {category == "Default Category" && (
                <div>
                    <div className='container-intro-home'>
                        {windowWidth > 600 ? (
                            <img src={imgIntro} alt='femme' className='home-imgIntro' />
                        ) : (
                            <img src={imgIntroPhone} alt='femme' className='home-imgIntro' />
                        )}

                        <div className='home-div-onImage'>
                            <h1 className='home-title-bold'>NIOSOMAL</h1>
                            <h1 className='home-title'>TECHNOLOGY</h1>
                            <div className='line-bold'></div>
                            <p className='home-p-bold'>The revolution in cosmetics.</p>
                            <p className='home-p'>Your best choice for maximum assimilation of active ingredients.</p>
                        </div>
                    </div>
                    <div className='container-text-intro-background'>
                        <p className='text-intro-background'>Offering innovative products with bioavailability.</p>
                        <p className='text-intro-background'>Maximized for efficiency focused and deep assured.</p>
                    </div>
                    <div className='container-content-global-home'>
                        <div className='container-expert-content'>
                            <img className='img-drop' alt='expert' src={drop} />
                            <div className='container-text-expert-content'>
                                <h2 className='title-expert-content'>
                                    Innovation and efficacy at the heart of cosmetics
                                </h2>
                                <p className='text-expert-content'>
                                    At Akore Formulations, we’re revolutionizing the world of cosmetics through innovation and scientific expertise.
                                    We use niosome technology to push back the boundaries of cosmetic efficacy.
                                </p>
                                <p className='text-expert-content'>
                                    Compared to traditional cosmetic formulations, niosome-based cosmetics offer numerous advantages,
                                    delivering active ingredients into the skin’s deepest layers for rapid, long-lasting action.
                                </p>
                                <button
                                    className='button-expert-content'
                                    onClick={() => setCategory("Serums")}
                                >
                                    Discover our products
                                </button>
                            </div>
                        </div>

                        <div className='akore-home-container-2'>
                            <div className='container-liposome-advantage'>
                                <div className='container-content-liposome-advantag'>
                                    <h2 className='title-liosome'>What’s a niosome ?</h2>
                                    <p className='home-p-desc align-justified'>
                                        Niosomes are non-ionic encapsulation systems designed to enhance the delivery of active ingredients to the skin.
                                        Thanks to their unique structure, they effectively encapsulate ingredients, enabling controlled release and improved skin penetration.
                                        The result is a significant increase in the efficacy of cosmetic products, while ensuring optimum protection of sensitive active ingredients.
                                    </p>
                                </div>
                                <img className='akore-container-2-img' alt='avantages' src={liposomeSchema} />
                            </div>

                            <div className='container-liposome-advantage2'>
                                <div className='container-content-liposome-advantag'>
                                    <h2 className='title-liosome'>Our expertise</h2>
                                    <p className='home-p-desc align-justified'>
                                        Our laboratory is at the forefront of research and development in the field of niosomes.
                                        We work closely with experts in dermatology and cosmetic formulation to create products that meet the specific needs of our customers.
                                    </p>
                                </div>

                                <div className='container-content-liposome-advantag'>
                                    <h2 className='title-liosome'>Our commitments</h2>
                                    <p className='home-p-desc-2'><strong>Innovation :</strong> We are committed to developing cutting-edge solutions that set new standards in the cosmetics industry.</p>
                                    <p className='home-p-desc-2'><strong>Quality :</strong> Each product is the result of in-depth research and rigorous quality control, ensuring safety and efficacy.</p>
                                    <p className='home-p-desc-2'><strong>Customisation :</strong> We offer tailor-made services to meet the unique requirements of each brand, enabling it to stand out from the crowd, from raw material to finished products.</p>
                                </div>
                            </div>
                        </div>

                        <div className='akore-home-container-3'>
                            <div className='akore-home-c3-duo'>
                                <div className='akore-home-icone-text'>
                                    <img className='icone-container-3' alt='icone goutte' src={icon1} />
                                    <h3>Powerful,</h3>
                                    <h3>profound</h3>
                                    <h3>effectiveness</h3>
                                </div>
                                <div className='akore-home-icone-text'>
                                    <img className='icone-container-3' alt='icon molécule graphe' src={icon2} />
                                    <h3>Long-Lasting Action :</h3>
                                    <h3>Controlled </h3>
                                    <h3>& Prolonged Release</h3>
                                </div>
                            </div>
                        </div>

                        <div className='akore-home-container-4'>
                            <div className='container-4-image-text'>
                                <img alt='matière première' src={windowWidth > 600 ? fondProduits : fondProduitsPhone} />
                                <div className='home-c-c4'>
                                    <h2 className='home-c4-h2'>FINISHED PRODUCTS</h2>
                                    <h3 className='home-c4-h3'>Serums and skincare routine</h3>
                                    <button className='home_serums-btn' onClick={() => setCategory('Serums')}>
                                        Serums
                                    </button>
                                    <button className='home_serums-btn transparence' onClick={() => setCategory('Mature Skin Routine')}>
                                        Skincare routines
                                    </button>
                                </div>
                            </div>

                            <div className='container-4-image-text'>
                                <img alt='matière première' src={windowWidth > 600 ? fondMp : fondMpPhone} />
                                <div className='home-c-c4'>
                                    <h2 className='home-c4-h2'>RAW MATERIALS</h2>
                                    <h3 className='home-c4-h3'>A selection of raw materials</h3>
                                    <button className='home_serums-btn w280' onClick={() => setCategory("Raw Materials")}>
                                        Raw materials
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
            }
            {category == "Raw Materials" && (
                <div>
                    <div className='container-all'>
                        <div className='container-product-category' id="rawMaterial">
                            <div className='item_in_product-category text_in_product_category'>
                                <h2 className='text'> RAW MATERIALS </h2>
                                <p className='small-text text'>A selection of raw</p>
                                <p className='small-text text'>liposomal materials </p>
                            </div>
                            {getRawProducts().map((product, index) => (
                                <div key={index} className='item_in_product-category'>
                                    <ProduitCard
                                        name={product.name}
                                        shortDesc={product.shortDesc}
                                        imgName={product.imgName}
                                        productKey={product.key}
                                        isRowProduct={true}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='otherproduct-container'>
                        <OtherProduct
                            title={"Serums"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct2}
                            category={"Serums"}
                            setCategory={setCategory}
                        />
                        <OtherProduct
                            title={"Skincare routines"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct3}
                            category={"Mature Skin Routine"}
                            setCategory={setCategory}
                        />
                    </div>
                </div>
            )
            }
            {category == "Serums" && (
                <div>
                    <div className='container-all'>
                        <div className='container-product-all'>
                            <div className='container-product-category' id='finishedProducts'>
                                <div className='item_in_product-category text_in_product_category'>
                                    <h2 className='text'>SERUMS</h2>
                                    <p className='small-text text'>A selection of finished</p>
                                    <p className='small-text text'>liposomal products</p>
                                </div>
                                {getFinishedProducts().map((product, index) => (
                                    <div key={index} className='item_in_product-category'>
                                        <ProduitCard
                                            name={product.name}
                                            shortDesc={product.shortDesc}
                                            imgName={product.imgName}
                                            productKey={product.key}
                                            isRowProduct={false}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='otherproduct-container'>
                        <OtherProduct
                            title={"Skincare routines"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct3}
                            category={"Mature Skin Routine"}
                            setCategory={setCategory}
                        />
                        <OtherProduct
                            title={"Raw materials"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct1}
                            category={"Raw Materials"}
                            setCategory={setCategory}
                        />
                    </div>
                </div>
            )
            }
            {category === "Mature Skin Routine" && (
                <div>
                    <div className='routine_global-container'>
                        <div className='routine_intro-container'>
                            <h1 className='routine_intro-title'>Skincare routines</h1>
                            <div className='horizontal-bar'></div>
                            <p className='routine_intro-subtitle'>A tailor-made routine for radiant skin, day after day.</p>
                            <div className='routine_intro_btn-container'>
                                <button
                                    className={`routiner_intro-btn ${typeState === 1 ? 'active' : ''}`}
                                    onClick={() => {
                                        setTypeState(1);
                                        setCategory("Mature Skin Routine");
                                    }}
                                >
                                    Mature skin
                                </button>

                                <button
                                    className={`routiner_intro-btn pink ${typeState === 2 ? 'active' : ''}`}
                                    onClick={() => {
                                        setTypeState(2);
                                        setCategory("Acne-Prone Skin Routine");
                                    }}
                                >
                                    Acne-prone skin
                                </button>
                            </div>
                            <p className='routine_intro-text'>
                                Take care of your mature skin with an expert routine, enriched with powerful anti-ageing active ingredients.
                                <br /><br />
                                Our selection of skincare products work in depth to cleanse, intensely moisturise and stimulate cell regeneration. Thanks to ingredients such as collagen, vitamin C and squalane, your skin regains suppleness, firmness and radiance. Apply these products morning and night for visible, long-lasting results.
                            </p>
                        </div>

                        {/* RoutineProduct avec type dynamique */}
                        <RoutineProduct type={typeState} />
                    </div>
                    <div className='otherproduct-container'>
                        <OtherProduct
                            title={"Serums"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct2}
                            category={"Serums"}
                            setCategory={setCategory}
                        />
                        <OtherProduct
                            title={"Raw materials"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct1}
                            category={"Raw Materials"}
                            setCategory={setCategory}
                        />
                    </div>
                </div>
            )
            }
            {category === "Acne-Prone Skin Routine" && (
                <div>
                    <div className='routine_global-container'>
                        <div className='routine_intro-container'>
                            <h1 className='routine_intro-title'>Skincare routines</h1>
                            <div className='horizontal-bar'></div>
                            <p className='routine_intro-subtitle'>A made-to-measure routine for radiant skin, day after day.</p>
                            <div className='routine_intro_btn-container'>
                                <button
                                    className={`routiner_intro-btn ${typeState === 1 ? 'active' : ''}`}
                                    onClick={() => {
                                        setTypeState(1);
                                        setCategory("Mature Skin Routine");
                                    }}
                                >
                                    Mature skin
                                </button>

                                <button
                                    className={`routiner_intro-btn pink ${typeState === 2 ? 'active' : ''}`}
                                    onClick={() => {
                                        setTypeState(2);
                                        setCategory("Acne-Prone Skin Routine");
                                    }}
                                >
                                    Acne-prone skin
                                </button>
                            </div>
                            <p className='routine_intro-text'>
                                Bring your blemish-prone skin back into balance with a targeted routine.
                                <br/><br/>
                                Designed to purify, soothe and regenerate acne-prone skin, our selection combines niacinamide, azelaic acid and zinc for maximum effectiveness. This routine helps to reduce shine, refine skin texture and prevent the appearance of imperfections, while maintaining optimal hydration.
                            </p>
                        </div>

                        {/* RoutineProduct avec type dynamique */}
                        <RoutineProduct type={typeState} />
                    </div>
                    <div className='otherproduct-container'>
                        <OtherProduct
                            title={"Serums"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct2}
                            category={"Serums"}
                            setCategory={setCategory}
                        />
                        <OtherProduct
                            title={"Raw materials"}
                            text={"A selection of finished liposomal products "}
                            img={icon_otherProduct1}
                            category={"Raw Materials"}
                            setCategory={setCategory}
                        />
                    </div>
                </div>
            )
            }
        </div>
    );
}
